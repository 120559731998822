// Twitter Icon:
// Simple Twitter icon.

// Core
import React from 'react'

const Twitter = () => (
  <svg version="1.1" width="20px" viewBox="0 0 20 20" xmlSpace="preserve">
    <path d="M13.8477 1.875C11.5814 1.875 9.74125 3.71109 9.74125 5.97656C9.74125 6.29812 9.77859 6.61391 9.84875 6.91406C6.43844 6.74297 3.41547 5.10891 1.39172 2.62687C1.03859 3.23266 0.835 3.93578 0.835 4.6875C0.835 6.11078 1.56031 7.365 2.66125 8.10063C1.98859 8.07922 1.35906 7.89516 0.805781 7.58797C0.805469 7.605 0.805781 7.62438 0.805781 7.64172C0.805781 9.62937 2.21969 11.2883 4.09687 11.6652C3.7525 11.7589 3.38703 11.8067 3.01281 11.8067C2.74844 11.8067 2.49172 11.7814 2.24125 11.7334C2.76328 13.3633 4.27875 14.5519 6.07422 14.585C4.67 15.6853 2.89891 16.338 0.976562 16.338C0.645469 16.338 0.320937 16.322 0 16.2842C1.81578 17.4483 3.97187 18.125 6.28906 18.125C13.8364 18.125 17.9639 11.8761 17.9639 6.455C17.9639 6.27703 17.9622 6.09875 17.9541 5.92281C18.7558 5.34438 19.4498 4.62141 20 3.79875C19.2641 4.125 18.4716 4.34406 17.6416 4.44328C18.4888 3.93563 19.1419 3.13078 19.4483 2.17281C18.6555 2.64281 17.7755 2.98484 16.8408 3.16891C16.0923 2.37172 15.0278 1.875 13.8477 1.875V1.875Z" />
  </svg>
)

export default Twitter
