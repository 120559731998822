// Instagram Icon:
// Simple Instagram icon.

// Core
import React from 'react'

const Instagram = () => (
  <svg version="1.1" width="100%" viewBox="0 0 32 32">
    <path d="M31.9,9.4a11.47,11.47,0,0,0-.74-3.88,7.64,7.64,0,0,0-1.85-2.83A7.64,7.64,0,0,0,26.48.84,11.47,11.47,0,0,0,22.6.1C20.89,0,20.35,0,16,0S11.11,0,9.4.1A11.47,11.47,0,0,0,5.52.84,7.64,7.64,0,0,0,2.69,2.69,7.64,7.64,0,0,0,.84,5.52,11.47,11.47,0,0,0,.1,9.4C0,11.11,0,11.65,0,16s0,4.89.1,6.6a11.47,11.47,0,0,0,.74,3.88,7.64,7.64,0,0,0,1.85,2.83,7.64,7.64,0,0,0,2.83,1.85,11.47,11.47,0,0,0,3.88.74c1.71.08,2.25.1,6.6.1s4.89,0,6.6-.1a11.47,11.47,0,0,0,3.88-.74,8.17,8.17,0,0,0,4.68-4.68,11.47,11.47,0,0,0,.74-3.88c.08-1.71.1-2.25.1-6.6S32,11.11,31.9,9.4ZM16,24.24A8.24,8.24,0,1,1,24.24,16,8.24,8.24,0,0,1,16,24.24ZM25.3,9.06a2.58,2.58,0,1,1,2.58-2.58A2.58,2.58,0,0,1,25.3,9.06Z" />
  </svg>
)

export default Instagram
