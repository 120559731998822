// Carat Icon:
// Simple carat (>) icon.

// Core
import React from 'react'
import theme from '../../../gatsby-plugin-theme-ui'

const Carat = () => (
  <svg width="18px" viewBox="0 0 35.46 111.79">
    <polyline fill="none" strokeMiterlimit="10" strokeWidth="4px" points="1.31 111.01 33.72 58.08 1.31 0.74" />
  </svg>
)

export default Carat
